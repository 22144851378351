.inputs-rows > div {
    margin-bottom: 20px;
}

.inputs-rows *:disabled {
    filter: blur(1px);
}

.blured {
    filter: blur(2px);
}

.just-padding {
    padding: 15px;
}

.list-group.list-group-root {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
    border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
    padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
    padding-left: 45px;
}


.image-cropper {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.profile-pic {
    display: inline;
    margin: 0 auto 0 -25%;
    height: 100%;
    width: auto;
}

.xl-modal {
    max-width: 90vw !important;
}

.group-students-list {
    list-style: unset;
}

.participant-present {
    color: greenyellow;
}

.participant-absent {
    color: red;
}
